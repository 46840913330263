import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Global, css } from '@emotion/react'
import { mobile, tablet, BlurFilter } from '~styles/global'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import Seo from '~components/Seo'
import DataViewer from '~utils/DataViewer'
import useSiteSettings from '~utils/useSiteSettings'
import Section from '~components/Section'
import Image from '~components/Image'
import resolveLink from '~utils/resolveLink'
import MovingVideo from '~components/MovingVideo'

const Projects = ({ data }) => {
	
	const films = data?.projects?.nodes
	const [filter, setFilter] = useState(null)
	const [currentSrc, setCurrentSrc] = useState(null)

	function lexographicalSort(a, b) {
		if (a['orderRank'] < b['orderRank']) {
			return -1
		}
		if (a['orderRank'] > b['orderRank']) {
			return 1
		}
		return 0
	}

	films.sort(lexographicalSort)

	return (
		<>
			<Global styles={css`
				:root{
					--olive: #1C1C1C;
					--olivetrans: rgba(28, 28, 28, 0);
					--black: #847747;
				}
			`} />
			<Seo title="Projects"/>
			<BlurFilter />
			<Header>
				<h2>Index</h2>
				<Filters>
					{data?.categories?.nodes?.map(cat => (
						<button
							key={cat.title}
							className="h4" 
							css={css`border-bottom: ${filter === cat.title ? '1px solid' : 0};`}
							onClick={() => filter === cat.title ? setFilter(null) : setFilter(cat.title)}>
							{cat.title}
						</button>
					))}
				</Filters>
			</Header>
			<Content>
				<List>
					{films?.map(project => (
						<ProjectLine 
							key={project._key} 
							to={resolveLink(project)} 
							onMouseEnter={() => setCurrentSrc(
								project.content?.thumbnailVideo ? project.content?.thumbnailVideo?.asset?.url : project.content?.thumbnailImage
							)}
							onMouseLeave={() => setCurrentSrc(null)}
							css={css`
							color: ${filter === project?.content?.cat?.title || !filter ? 'var(--black)' : '#3B3729'};
							pointer-events: ${filter === project?.content?.cat?.title || !filter ? 'all' : 'none'};
						`}
						>
							<h1 className="h6">{project.title}</h1>
							<h6>{project.content?.subtitle ?? project.content?.category}</h6>
						</ProjectLine>
					))}
				</List>
			</Content>
			<MovingVideo src={currentSrc}/>
			<DataViewer data={data} name="data"/>
		</>
	)
}

const Content = styled(Section)`
	margin-bottom: var(--xxl);
`

const List = styled.div`
	grid-column: 3 / 13;
	${mobile}{
		grid-column: span 12;
	}
`

const Header = styled(Section)`
	margin: var(--xxxxxl) 0 var(--xxl);
	h2{
		grid-column: span 2;
		${mobile}{
			grid-column: span 4;
		}
	}
`

const Filters = styled.div`
	grid-column: span 8;
	${mobile}{
		grid-column: span 8;
		text-align: right;
	}
	button{
		margin-right: var(--s);
		${mobile}{
			margin-right: 0;
			margin-left: var(--s);
		}
	}
`

const ProjectLine = styled(Link)`
	display: grid;
	grid-template-columns: repeat(10, 1fr);
	grid-column-gap: 20px;
	border-top: 1px solid var(--black);
	padding: var(--xxs) 0 var(--s);
	transition: color 0.8s;
	&:hover{
		color: #A29565;
	}
	.h6{
		grid-column: span 4;
		${mobile}{
			grid-column: span 5;
		}
	}
	h6{
		grid-column: span 6;
		${mobile}{
			grid-column: span 5;
		}
	}
`

export const query = graphql`
  query ProjectsQuery {
		categories: allSanityCategory(sort: {fields: title, order: ASC}) {
			nodes {
				title
			}
		}
    projects: allSanityProject {
      nodes{
				_key
				_type
				title
				orderRank
				slug{
					current
				}
				content {
					subtitle
					cat{
						title
					}
					thumbnailVideo {
						asset {
							url
						}
					}
					thumbnailImage {
						...imageWithAlt
					}
				}
			}
    }
  }
`

Projects.propTypes = {
	data: PropTypes.object,
}

export default Projects
